<template>
  <DetailTemplate
    :customClass="'user-detail detail-page'"
    v-if="getPermission('user:view')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="50"
        type="text"
      >
      </v-skeleton-loader>
      <h1 v-else class="form-title custom-nowrap-ellipsis text-capitalize">
        {{ getPageTitle }}
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="d-inline-block ml-2"
              label
              color="green white--text"
              v-if="userArr.activated"
              >Active</v-chip
            >
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="d-inline-block ml-2"
              label
              v-else
              color="red darken-2 white--text"
              >In-Active</v-chip
            >
          </template>
          <span>Status</span>
        </v-tooltip>
        <v-tooltip v-if="userArr.role" top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="d-inline-block ml-2"
              label
              color="orange darken-2 white--text"
              >{{ userArr.role.name }}</v-chip
            >
          </template>
          <span>Role</span>
        </v-tooltip>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-layout class="float-right">
        <v-flex v-if="userTab == 'tab-commission'" class="mr-4"
          ><DateRangePicker
            v-on:change="loadContent()"
            v-model="dates"
            :page-loading="pageLoading"
          ></DateRangePicker
        ></v-flex>
        <v-flex v-if="getPermission('user:update')" class="d-flex justify-end">
          <v-btn
            :disabled="pageLoading || roleLoading"
            :loading="roleLoading"
            class="mx-2 custom-grey-border custom-bold-button text-white"
            color="cyan"
            v-on:click="getRoles"
          >
            <v-icon small left>mdi-pencil</v-icon>
            Edit
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="goBack"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-layout>
        <v-flex md2 class="px-4 py-4" style="position: relative">
          <v-avatar size="100">
            <v-img
              contain
              :lazy-src="$assetAPIURL(userArr.profile_logo)"
              :src="$assetAPIURL(userArr.profile_logo)"
              max-height="100"
              max-width="100"
              height="100px"
              width="100px"
              class="margin-auto"
            >
            </v-img>
          </v-avatar>
          <div class="margin-auto">
            <v-btn
              class="my-4 custom-bold-button"
              outlined
              x-small
              v-on:click="selectImage"
              color="cyan"
              :loading="imageLoading"
              :disabled="imageLoading"
            >
              Change Image
            </v-btn>
            <div class="d-none">
              <v-file-input
                ref="uploadImage"
                v-on:change="updateProfileImage"
                :rules="imageRules"
                accept="image/png, image/jpeg, image/jpg"
              ></v-file-input>
            </div>
          </div>
        </v-flex>
        <v-flex md8 class="px-4 py-4">
          <table width="100%">
            <tr>
              <td width="15%" class="font-size-16 font-weight-500">
                First name
              </td>
              <td width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.first_name">{{
                  userArr.first_name
                }}</template>
                <template v-else
                  ><em class="text--secondary">No First name</em></template
                >
              </td>
              <td width="15%" class="font-size-16 font-weight-500">
                Last name
              </td>
              <td width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.last_name">{{
                  userArr.last_name
                }}</template>
                <template v-else
                  ><em class="text--secondary">No Last name</em></template
                >
              </td>
            </tr>
            <tr>
              <td width="15%" class="font-size-16 font-weight-500">Email</td>
              <td width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.user_email">{{
                  userArr.user_email
                }}</template>
                <template v-else
                  ><em class="text--secondary">No Email</em></template
                >
              </td>
              <td width="15%" class="font-size-16 font-weight-500">
                User name
              </td>
              <td width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.user_name">{{
                  userArr.user_name
                }}</template>
                <template v-else
                  ><em class="text--secondary">No User name</em></template
                >
              </td>
            </tr>
            <tr>
              <td width="15%" class="font-size-16 font-weight-500">
                Display name
              </td>
              <td width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.display_name">{{
                  userArr.display_name
                }}</template>
                <template v-else
                  ><em class="text--secondary">No Display name</em></template
                >
              </td>
              <td width="15%" class="font-size-16 font-weight-500">
                Phone number
              </td>
              <td width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.phone_number">{{
                  userArr.phone_number
                }}</template>
                <template v-else
                  ><em class="text--secondary">No Phone Number</em></template
                >
              </td>
            </tr>
            <tr>
              <td width="15%" class="font-size-16 font-weight-500">
                Designation
              </td>
              <td width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.display_name"
                  ><span class="text-capitalize">{{
                    userArr.designation
                  }}</span></template
                >
                <template v-else
                  ><em class="text--secondary">No Designation</em></template
                >
              </td>
              <td v-if="false" width="15%" class="font-size-16 font-weight-500">
                Phone number
              </td>
              <td v-if="false" width="35%" class="font-size-16 font-weight-600">
                <template v-if="userArr.phone_number">{{
                  userArr.phone_number
                }}</template>
                <template v-else
                  ><em class="text--secondary">No Phone Number</em></template
                >
              </td>
            </tr>
          </table>
        </v-flex>
        <v-flex md2 class="px-4 py-4"> </v-flex>
      </v-layout>
      <v-tabs
        v-model="userTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#tab-' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="position: static"
      >
        <v-tabs-items v-model="userTab">
          <v-tab-item value="tab-history">
            <RecentActivity :user.sync="user"></RecentActivity>
          </v-tab-item>
          <v-tab-item v-if="false" value="tab-commission">
            <v-row class="mx-4 tab-commission">
              <v-col md="12">
                <div class="row m-0">
                  <div
                    class="col mr-4 d-flex align-items-center mb-9 bg-light-warning rounded p-2"
                  >
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-warning mr-5">
                      <span class="svg-icon svg-icon-4x">
                        <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                        <inline-svg
                          :src="
                            $assetURL('media/svg/icons/Shopping/Dollar.svg')
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <!--end::Icon-->
                    <!--begin::Title-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <p
                        class="font-weight-bolder text-warning font-size-lg mb-1 m-0"
                      >
                        Accepted Quotation Amount
                      </p>
                      <span
                        class="font-weight-bolder text-warning py-1 font-size-h4"
                        >{{
                          formatMoney(userArr.commissions_sum.total_amount)
                        }}</span
                      >
                    </div>
                    <!--end::Title-->
                  </div>
                  <div
                    class="col mx-4 d-flex align-items-center mb-9 bg-light-secondary rounded p-2"
                  >
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-secondary mr-5">
                      <span class="svg-icon svg-icon-4x">
                        <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                        <inline-svg
                          :src="
                            $assetURL('media/svg/icons/Shopping/Dollar.svg')
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <!--end::Icon-->
                    <!--begin::Title-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <p
                        class="font-weight-bolder text-secondary font-size-lg mb-1 m-0"
                      >
                        Accepted Quotation Profit
                      </p>
                      <span
                        class="font-weight-bolder text-secondary py-1 font-size-h4"
                        >{{
                          formatMoney(userArr.commissions_sum.profit_margin)
                        }}</span
                      >
                    </div>
                    <!--end::Title-->
                  </div>
                  <div
                    class="col mx-4 d-flex align-items-center mb-9 bg-light-danger rounded p-2"
                  >
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-danger mr-5">
                      <span class="svg-icon svg-icon-4x">
                        <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                        <inline-svg
                          :src="
                            $assetURL('media/svg/icons/Shopping/Dollar.svg')
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <!--end::Icon-->
                    <!--begin::Title-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <p
                        class="font-weight-bolder text-danger font-size-lg mb-1 m-0"
                      >
                        Invoice Amount
                      </p>
                      <span
                        class="font-weight-bolder text-danger py-1 font-size-h4"
                        >{{
                          formatMoney(userArr.commissions_sum.total_invoiced)
                        }}</span
                      >
                    </div>
                    <!--end::Title-->
                  </div>
                  <div
                    class="col mx-4 d-flex align-items-center mb-9 bg-light-success rounded p-2"
                  >
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-success mr-5">
                      <span class="svg-icon svg-icon-4x">
                        <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                        <inline-svg
                          :src="
                            $assetURL('media/svg/icons/Shopping/Dollar.svg')
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <!--end::Icon-->
                    <!--begin::Title-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <p
                        class="font-weight-bolder text-success font-size-lg mb-1 m-0"
                      >
                        Paid Invoice Amount
                      </p>
                      <span
                        class="font-weight-bolder text-success py-1 font-size-h4"
                        >{{
                          formatMoney(userArr.commissions_sum.total_paid)
                        }}</span
                      >
                    </div>
                    <!--end::Title-->
                  </div>
                  <div
                    class="col ml-4 d-flex align-items-center mb-9 bg-light-info rounded p-2"
                  >
                    <!--begin::Icon-->
                    <span class="svg-icon svg-icon-info mr-5">
                      <span class="svg-icon svg-icon-4x">
                        <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                        <inline-svg
                          :src="
                            $assetURL('media/svg/icons/Shopping/Dollar.svg')
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <!--end::Icon-->
                    <!--begin::Title-->
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                      <p
                        class="font-weight-bolder text-info font-size-lg mb-1 m-0"
                      >
                        Commission
                      </p>
                      <template v-if="false && userArr.commission_type">
                        <p
                          class="font-weight-bolder text-info font-size-12 mb-1 m-0"
                        >
                          ({{ userArr.commission }}% of
                          {{ userArr.commission_type }})
                        </p>
                        <p
                          v-if="
                            userArr.commission_start && userArr.commission_end
                          "
                          class="font-weight-bolder text-info font-size-12 mb-1 m-0"
                        >
                          {{ formatDate(userArr.commission_start) }} -
                          {{ formatDate(userArr.commission_end) }}
                        </p>
                      </template>
                      <span
                        class="font-weight-bolder text-info py-1 font-size-h4"
                        >{{
                          formatMoney(userArr.commissions_sum.total_commission)
                        }}</span
                      >
                    </div>
                    <!--end::Title-->
                  </div>
                </div>
                <table width="100%">
                  <thead>
                    <tr>
                      <th class="simple-table-th">#</th>
                      <th class="simple-table-th">Quotation</th>
                      <th class="simple-table-th">Quotation Amount</th>
                      <th class="simple-table-th">Invoice</th>
                      <th class="simple-table-th">Invoice Amount</th>
                      <th class="simple-table-th">Commission</th>
                    </tr>
                  </thead>
                  <tbody v-if="userArr.commissions.length">
                    <tr
                      class="custom-border-bottom"
                      v-for="(row, qkey) in userArr.commissions"
                      :key="qkey"
                    >
                      <td class="cursor-default simple-table-td">
                        {{ qkey + 1 }}.
                      </td>
                      <td
                        class="simple-table-td"
                        v-on:click="pushToQuotation(row)"
                      >
                        <Barcode
                          route="quotation.detail"
                          :barcode="row.quotation_barcode"
                          :id="row.quotation_id"
                        ></Barcode>
                      </td>
                      <td class="cursor-default simple-table-td">
                        <p class="m-0 p-0">
                          <span class="font-weight-600">Total Amount: </span
                          >{{ formatMoney(row.total_amount) }}
                        </p>
                        <p class="m-0 p-0">
                          <span class="font-weight-600">Profit: </span
                          >{{ formatMoney(row.profit_margin) }}
                        </p>
                        <p class="m-0 p-0">
                          <span class="font-weight-600">Created At: </span
                          >{{ formatDateTime(row.quotation_added_at) }}
                        </p>
                      </td>
                      <td
                        class="simple-table-td"
                        v-on:click="pushToInvoice(row)"
                      >
                        <Barcode
                          v-if="row.invoice_id"
                          route="invoice.detail"
                          :barcode="row.invoice_barcode"
                          :id="row.invoice_id"
                        ></Barcode>
                        <em v-else class="text--secondary"
                          >Not Invoiced Yet.</em
                        >
                      </td>
                      <td class="cursor-default simple-table-td">
                        <template v-if="row.invoice_id">
                          <p class="m-0 p-0">
                            <span class="font-weight-600">Total Amount: </span
                            >{{ formatMoney(row.total_invoiced) }}
                          </p>
                          <p class="m-0 p-0">
                            <span class="font-weight-600">Paid Amount: </span
                            >{{ formatMoney(row.total_paid) }}
                          </p>
                          <p class="m-0 p-0">
                            <span class="font-weight-600">Created At: </span
                            >{{ formatDateTime(row.invoice_added_at) }}
                          </p>
                        </template>
                        <em v-else class="text--secondary"
                          >Not Invoiced Yet.</em
                        >
                      </td>
                      <td class="cursor-default simple-table-td">
                        <p class="m-0 p-0">
                          <span class="font-weight-600">Commission: </span
                          >{{ formatMoney(row.total_commission) }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-else>
                    <tr>
                      <td colspan="6">
                        <p
                          class="m-0 py-4 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no transactions at the moment.
                        </p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-password">
            <PasswordUpdate
              :user.sync="user"
              v-on:password:updated="userTab = 'tab-history'"
            ></PasswordUpdate>
          </v-tab-item>
          <v-tab-item value="tab-message">
            <div>
              <v-layout
                class="p-4 border-bottom-light-grey min-height-57px"
                style="position: sticky; top: 0; background-color: #fff"
              >
                <v-flex class="font-size-16 font-weight-600 my-auto">
                  <span class="detail-svg-icon mr-2">
                    <!--begin::Svg Icon-->
                     <v-icon color="cyan"> mdi-message-text </v-icon>
                    <!--end::Svg Icon-->
                  </span>
                  Messages
                </v-flex>
              </v-layout>
              <v-form
                    ref="commentForm"
                    v-model.trim="formValid"
                    lazy-validation
                    v-on:submit.stop.prevent="updateMessage()"
                  >
                    <v-layout class="px-3 pt-3 border-top-light-grey">
                      <v-flex md9>
                        <v-textarea
                          v-model.trim="custom_message"
                          auto-grow
                          dense
                          filled
                          label="Enter Message...."
                          v-on:keypress="(e) => manageLimit(e)"
                          v-on:paste="(e) => onPaste(e)"
                          solo
                          flat
                          row-height="30"
                          color="cyan"
                          :rules="[
                            validateRules.required(custom_message, 'Message'),
                          ]"
                        ></v-textarea>
                        <div class="font-weight-600 text-end">
                          {{
                           custom_message
                              ?custom_message.length
                              : 0
                          }}/160
                        </div>
                      </v-flex>
                      <v-flex md3 class="ml-2 d-flex">
                        <v-layout>
                          <v-flex md12>
                            <v-btn
                              v-on:click="updateMessage()"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan w-100"
                              class="mx-2 custom-grey-border custom-bold-button text-white"
                              depressed
                              tile
                              ><v-icon small left>mdi-playlist-plus</v-icon>Save Message</v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-form>
            </div>
          </v-tab-item>
          <!-- <v-tab-item value="tab-leave">
            <v-container fluid class="white-background">
              <v-row>
                <v-col md="12">
                  <div class="w-100 d-flex justify-content-end">
                    <v-btn
                      tile
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      v-on:click="LeaveDialog = true"
                    >
                      + Create
                      <v-on></v-on>
                    </v-btn>
                  </div>
                </v-col>
                <v-col md="12">
                  <table width="100%" height="70px">
                    <thead>
                      <tr style="background-color: black">
                        <th class="detail-head">#</th>
                        <th class="detail-head">Leave Day</th>
                        <th class="detail-head">Start Date</th>
                        <th class="detail-head">End Date</th>
                        <th class="detail-head">Reason</th>
                        <th class="detail-head">Attachment Name</th>
                        <th class="detail-head">Attachment</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="custom-border-bottom">
                        <td class="cursor-default leave-table-td">1</td>
                        <td class="leave-table-td">12</td>
                        <td class="leave-table-td">12-02-2022</td>
                        <td class="cursor-default leave-table-td">
                          15-02-2022
                        </td>
                        <td class="cursor-default leave-table-td">
                          Any Reasonvfsdfsd dvsdfd vsdfsdf sdgdsf
                        </td>
                        <td class="cursor-default leave-table-td">
                          Attachment Name
                        </td>
                        <td class="cursor-default leave-table-td">
                          Attachment
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-container>
            <LeaveDialog
              :LeaveDialog.sync="LeaveDialog"
              v-on:closeDialog="LeaveDialog = false"
              v-on:resetAll="LeaveDialog = false"
            ></LeaveDialog>
          </v-tab-item> -->
          <v-tab-item value="tab-login-log">
            <LoginLog :user.sync="user" :logs.sync="loginLogs"></LoginLog>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
      <Dialog :commonDialog.sync="updateDialog" :dialog-width="dialogWidth">
        <template v-slot:title> Edit User </template>
        <template v-slot:body>
          <v-form
            ref="userForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="submitUser"
          >
            <v-row class="create-form">
              <v-col md="10" class="py-0 pt-4" offset-md="1">
                <v-row dense>
                  <v-col v-if="false" md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Salutation</label
                    >
                    <v-select
                      :items="salutationList"
                      dense
                      filled
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      label="Salutation"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.title"
                      :rules="[
                        validateRules.required(updateUser.title, 'Salutation'),
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >First Name</label
                    >
                    <v-text-field
                      dense
                      filled
                      label="First Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.first_name"
                      :rules="[
                        validateRules.required(
                          updateUser.first_name,
                          'First Name'
                        ),
                        validateRules.minLength(
                          updateUser.first_name,
                          'First Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.first_name,
                          'First Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Last Name</label
                    >
                    <v-text-field
                      dense
                      filled
                      label="Last Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.last_name"
                      :rules="[
                        validateRules.required(
                          updateUser.last_name,
                          'Last Name'
                        ),
                        validateRules.minLength(
                          updateUser.last_name,
                          'Last Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.last_name,
                          'Last Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="10" class="py-0" offset-md="1">
                <v-row dense>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Email</label
                    >
                    <v-text-field
                      dense
                      filled
                      label="Email"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.user_email"
                      :rules="[
                        validateRules.validEmail(
                          updateUser.user_email,
                          'Email'
                        ),
                        validateRules.required(updateUser.user_email, 'Email'),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Role</label
                    >
                    <v-select
                      :items="roles"
                      dense
                      filled
                      item-text="name"
                      item-value="id"
                      item-color="cyan"
                      label="Role"
                      solo
                      flat
                      v-model.trim="updateUser.role"
                      color="cyan"
                      hide-details
                      :rules="[validateRules.required(updateUser.role, 'Role')]"
                    ></v-select>
                    <em class="font-size-10" v-if="updateUser.role == 1"
                      >Unrestricted access to all modules.</em
                    >
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Display Name</label
                    >
                    <v-text-field
                      dense
                      filled
                      label="Display Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.display_name"
                      :rules="[
                        validateRules.required(
                          updateUser.display_name,
                          'Display Name'
                        ),
                        validateRules.minLength(
                          updateUser.display_name,
                          'Display Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.display_name,
                          'Display Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="false" md="12" class="py-0">
                    <label class="font-weight-600 font-size-16"
                      >Commission Duration</label
                    >
                    <v-layout>
                      <v-flex md6>
                        <Datepicker
                          :default-date="updateUser.commission_start"
                          v-model="updateUser.commission_start"
                          :max-date="updateUser.commission_end"
                          show-details
                          solo
                          :nullable="!updateUser.commission_start"
                          placeholder="Start Date"
                        ></Datepicker>
                      </v-flex>
                      <v-flex md6>
                        <Datepicker
                          :default-date="updateUser.commission_end"
                          v-model="updateUser.commission_end"
                          :min-date="updateUser.commission_start"
                          show-details
                          solo
                          :nullable="!updateUser.commission_end"
                          placeholder="End Date"
                        ></Datepicker>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col v-if="false" md="12" class="py-0">
                    <label class="font-weight-600 font-size-16"
                      >Commission</label
                    >
                    <v-layout>
                      <v-flex md4>
                        <v-text-field
                          dense
                          filled
                          solo
                          flat
                          suffix="% of "
                          v-mask="'###'"
                          color="cyan"
                          v-model.trim="updateUser.commission"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex md8>
                        <v-select
                          :items="commissionType"
                          dense
                          filled
                          solo
                          flat
                          hide-details
                          v-model.trim="updateUser.commission_type"
                          color="cyan"
                          item-color="cyan"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="10" class="py-0" offset-md="1">
                <v-row dense>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Designation</label
                    >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="designationList"
                      v-model.trim="updateUser.designation"
                      label="Designation"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageDesignationDialog = true"
                      item-color="cyan"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Designation Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Phone Number</label
                    >
                    <PhoneTextField
                      id="t-primary_phone"
                      v-model="updateUser.phone_number"
                      :value="updateUser.phone_number"
                      required
                    >
                    </PhoneTextField>
                  </v-col>
                  <v-col v-if="false" md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Role</label
                    >
                    <v-select
                      :items="roles"
                      dense
                      filled
                      item-text="name"
                      item-value="id"
                      item-color="cyan"
                      label="Role"
                      solo
                      flat
                      v-model.trim="updateUser.role"
                      color="cyan"
                      hide-details
                      :rules="[validateRules.required(updateUser.role, 'Role')]"
                    ></v-select>
                    <em class="font-size-10" v-if="updateUser.role == 1"
                      >Unrestricted access to all modules.</em
                    >
                  </v-col>
                  <v-col v-if="false" md="4" class="py-0">
                    <label class="font-weight-600 font-size-16 required"
                      >Display Name</label
                    >
                    <v-text-field
                      dense
                      filled
                      label="Display Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.display_name"
                      :rules="[
                        validateRules.required(
                          updateUser.display_name,
                          'Display Name'
                        ),
                        validateRules.minLength(
                          updateUser.display_name,
                          'Display Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.display_name,
                          'Display Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="false" md="12" class="py-0">
                    <label class="font-weight-600 font-size-16"
                      >Commission Duration</label
                    >
                    <v-layout>
                      <v-flex md6>
                        <Datepicker
                          :default-date="updateUser.commission_start"
                          v-model="updateUser.commission_start"
                          :max-date="updateUser.commission_end"
                          show-details
                          solo
                          :nullable="!updateUser.commission_start"
                          placeholder="Start Date"
                        ></Datepicker>
                      </v-flex>
                      <v-flex md6>
                        <Datepicker
                          :default-date="updateUser.commission_end"
                          v-model="updateUser.commission_end"
                          :min-date="updateUser.commission_start"
                          show-details
                          solo
                          :nullable="!updateUser.commission_end"
                          placeholder="End Date"
                        ></Datepicker>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col v-if="false" md="12" class="py-0">
                    <label class="font-weight-600 font-size-16"
                      >Commission</label
                    >
                    <v-layout>
                      <v-flex md4>
                        <v-text-field
                          dense
                          filled
                          solo
                          flat
                          suffix="% of "
                          v-mask="'###'"
                          color="cyan"
                          v-model.trim="updateUser.commission"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex md8>
                        <v-select
                          :items="commissionType"
                          dense
                          filled
                          solo
                          flat
                          hide-details
                          v-model.trim="updateUser.commission_type"
                          color="cyan"
                          item-color="cyan"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            :disabled="!formValid || formLoading"
            :loading="formLoading"
            v-on:click="submitUser"
          >
            Update
          </v-btn>
          <v-btn
            :disabled="formLoading"
            class="custom-grey-border custom-bold-button"
            v-on:click="updateDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <ManageDesignation
        :common-dialog="manageDesignationDialog"
        :designations="designationList"
        v-on:close-dialog="manageDesignationDialog = false"
        v-on:get-designation="getOptions()"
      ></ManageDesignation>
    </template>
  </DetailTemplate>
</template>

<style scoped>
.tab-commission .simple-table-th {
  padding: 6px 16px !important;
}
</style>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import Dialog from "@/view/pages/partials/Dialog";
import { GET, PATCH, QUERY } from "@/core/services/store/request.module";
import LoginLog from "@/view/pages/user/detail/LoginLog";
import PasswordUpdate from "@/view/pages/user/detail/PasswordUpdate";
import RecentActivity from "@/view/pages/user/detail/RecentActivity";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Datepicker from "@/view/pages/partials/Datepicker";
import DateRangePicker from "@/view/pages/partials/DateRangePicker";
//import LeaveDialog from "@/view/pages/partials/LeaveDialog.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ManageDesignation from "@/view/pages/partials/Manage-Designation.vue";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";

import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, FileManagerMixin],
  name: "user-detail",
  data() {
    return {
      // radioGroup: 1,
      LeaveDialog: false,
      user: 0,
      dates: [],
      designationList: [],
      manageDesignationDialog: false,
      userTab: "tab-history",
      pageLoading: true,
      roleLoading: false,
      updateDialog: false,
      formValid: true,
      formLoading: false,
      loginLogs: [],
      userArr: {
        title: null,
        first_name: null,
        last_name: null,
        user_name: null,
        user_email: null,
        phone_number: null,
        role: null,
        display_name: null,
        commission_start: null,
        commission_end: null,
        commission: 0,
        commissions: [],
        commission_type: null,
        commission_type_id: null,
        profile_logo: null,
        commissions_sum: {
          total_amount: null,
          profit_margin: null,
          total_invoiced: null,
          total_paid: null,
          total_commission: null,
        },
      },
      roles: [],
      imageLoading: false,
      commissionType: [
        {
          text: "Quotation Amount",
          value: 1,
        },
        {
          text: "Quotation Profit",
          value: 2,
        },
        {
          text: "Invoice Amount",
          value: 3,
        },
        {
          text: "Invoice Paid Amount",
          value: 4,
        },
      ],
      custom_message : null,
      tabs: [
        /*{
          title: "Stats",
          icon: "mdi-credit-card",
          key: "commission",
          disabled: false,
        },*/
        {
          title: "Recent Activities",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
        /*{
          title: "Login Log",
          icon: "mdi-credit-card",
          key: "login-log",
          disabled: false
        },*/
        {
          title: "Password",
          icon: "mdi-form-textbox-password",
          key: "password",
          disabled: false,
        },
        {
          title: "Message",
          icon: "mdi-form-textbox-password",
          key: "message",
          disabled: false,
        },
        /* {
          title: "Leave",
          icon: "mdi-credit-card",
          key: "leave",
          disabled: false,
        }, */
      ],
      updateUser: {
        title: null,
        first_name: null,
        last_name: null,
        user_name: null,
        user_email: null,
        phone_number: null,
        role: null,
        display_name: null,
        commission_start: null,
        commission_end: null,
        commission: 0,
        commission_type: 1,
        profile_logo: null,
      },
    };
  },
  methods: {
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.custom_message;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 159) {
        let trimValue = finalval.substring(0, 159);
        this.custom_message = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.custom_message &&
        this.custom_message.length > 159
      ) {
        e.preventDefault();
      }
    },
    updateMessage() {
      const _this = this;
      if (!_this.$refs.commentForm.validate()) {
        return false;
      }
      _this.$store
          .dispatch(PATCH, { url: `message/${this.userArr?.id}`, data: { message: this.custom_message } })
          .then(() => {
            _this.loadContent();
          })
          .catch((error) => {
            console.log({ error });
          });
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "user/options" })
        .then(({ data }) => {
          _this.designationList = data.designation;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.userArr.profile_logo = _this.lodash.head(response);
          _this.$store
            .dispatch(PATCH, {
              url: `user/${_this.user}/profile`,
              data: { profile: _this.userArr.profile_logo.id },
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.loadContent();
              _this.formLoading = false;
            });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    pushToQuotation({ quotation_id }) {
      if (quotation_id) {
        this.$router.push(
          this.getDefaultRoute("quotation.detail", {
            params: { id: quotation_id },
          })
        );
      }
    },
    pushToInvoice({ invoice_id }) {
      if (invoice_id) {
        this.$router.push(
          this.getDefaultRoute("invoice.detail", {
            params: { id: invoice_id },
          })
        );
      }
    },
    submitUser() {
      const _this = this;
      if (!_this.$refs.userForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "user/" + _this.user,
          data: _this.updateUser,
        })
        .then(() => {
          _this.updateDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.formLoading = false;
        });
    },
    getUser(filter) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, { url: "user/" + _this.user, data: filter })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getRoles() {
      const _this = this;
      _this.roleLoading = true;
      _this.updateUser = {
        title: _this.lodash.lowerCase(_this.userArr.title),
        first_name: _this.userArr.first_name,
        last_name: _this.userArr.last_name,
        designation: _this.userArr.designation,
        user_email: _this.userArr.user_email,
        phone_number: _this.userArr.phone_number,
        role: _this.userArr.role ? _this.userArr.role.id : 0,
        display_name: _this.userArr.display_name,
        commission_start: _this.userArr.commission_start,
        commission_end: _this.userArr.commission_end,
        commission: _this.userArr.commission,
        commission_type: _this.userArr.commission_type_id,
      };
      _this.$store
        .dispatch(GET, { url: "role" })
        .then(({ data }) => {
          _this.roles = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.updateDialog = true;
          _this.roleLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      const filter = new Object({});
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (_this.dates[0] && !_this.dates[1]) {
          return false;
        }
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
        filter.start_date = moment(_this.dates[0]).format("YYYY-MM-DD");
        filter.end_date = moment(_this.dates[1]).format("YYYY-MM-DD");
      }

      _this.pageLoading = true;

      _this
        .getUser(filter)
        .then((response) => {
          _this.userArr = response;
          _this.custom_message = _this.userArr?.custom_message?.message ?? null; 
          _this.loginLogs = response.login_details || [];
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    //LeaveDialog,
    DetailTemplate,
    ManageDesignation,
    PhoneTextField,
    LoginLog,
    Datepicker,
    DateRangePicker,
    Dialog,
    Barcode,
    PasswordUpdate,
    RecentActivity,
  },
  mounted() {
    const _this = this;
    _this.loadContent();
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "User", route: "user" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.user = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.user || _this.user <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getPageTitle() {
      return this.userArr ? this.userArr.full_name : "";
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
